.navigation_bar {
    padding-top: 35px;
    height: calc(100vh - 160px);
    overflow: hidden scroll;
}

.exam_timer {
    font-size: 40px;
    font-weight: 700;
}

.questions_nav {
    display: flex;
    flex-wrap: wrap;
}

.questions_nav .question_el {
    background: #fff;
    border: 4px solid #ECECEC;
    border-radius: 12px;
    font-weight: 800;
    font-size: 18px;
    line-height: 20px;
    color: #333333;
    width: 40px;
    padding: 6px 0;
    text-align: center;
    cursor: pointer;
    margin: 2px 0;
}

.questions_nav .question_el.small {
    font-size: 16px;
}

.questions_nav .question_el.xsmall {
    font-size: 13px;
}

.bocconi .questions_nav .question_el {
    font-weight: 700;
}

.questions_nav .question_el_external {
    border: 3px dashed transparent;
    margin-top: 8px;
}
.questions_nav .question_el_external.block_wrapped {
    border-top: 3px dashed #68BAE8;
    border-bottom: 3px dashed #68BAE8;
}

.questions_nav .question_el_external.block_wrapped_first {
    border-left: 3px dashed #68BAE8;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.questions_nav .question_el_external.block_wrapped_last {
    border-right: 3px dashed #68BAE8;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    margin-right: 8px;
}

.questions_nav .question_el.answered {
    background: #fff;
    border: 4px solid #296D9F;
    color: #333333;
}

.bocconi .questions_nav .question_el.answered {
    background: #fff;
    border: 4px solid var(--bs-primary);
    color: #333333;
}

.questions_nav .question_el.is_uncertain {
    border: 4px solid #FCBB0D;
}

.questions_nav .question_el.current {
    color: #fff;
    border: 4px solid #296D9F;
    background: #296D9F;
}

.bocconi .questions_nav .question_el.current {
    color: #fff;
    border: 4px solid var(--bs-primary);
    background: var(--bs-primary);
}

.questions_nav .question_el.disabled_question {
    opacity: 0.6;
    cursor: default;
}

.time_pbar {
    height: 12px;
}

.progress-bar {
    background: linear-gradient(270deg, #4F95CC 1.2%, #296D9F 100%);
}

.bocconi .progress-bar {
    background: linear-gradient(270deg, var(--bs-primary) 1.2%, var(--bs-primary) 100%);
}

.linear_toggle {
    margin-top: 20px;
    width: 70%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.linear_toggle .switch_icon {
    height: 35px;
    display: flex;
    cursor: pointer;
}

.linear_toggle .switch_text {
    font-size: 15px;
    margin-left: 10px;
}