@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.modal__title {
    font-weight: 800;
    font-size: 24px;
    line-height: 120%;
    color: #373737;
}

.bocconi .modal__title {
    font-family: 'Open Sans' !important;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    color: #373737;
}

.text-2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: rgba(55, 55, 55, 0.75);
    white-space:nowrap;
}

.text-3 {
    font-weight: 800;
    font-size: 18px;
    line-height: 130%;
    color: #373737;
}

.text-4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: rgba(55, 55, 55, 0.75);
}

.icon_result {
    width: 20px;
}

.header_image_big {
    width: 40px;
}

.text-details {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: rgba(55, 55, 55, 0.75);
}

.text-details-2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    color: rgba(55, 55, 55, 0.5);
}

.details-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: rgba(55, 55, 55, 0.75);
}

.details-score {
    font-weight: 800;
    font-size: 18px;
    line-height: 130%;
    color: #373737;
}