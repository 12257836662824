.pw_label img {
    width: 130px;
}
.pw_label_smaller img {
    width: 110px;
}

header {
    padding: 12px 24px;
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100%);
    z-index: 100;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.language_selector{
    display: flex;
    align-items: center;
}
.language_selector img{
    width: 24px;
    height: 100%;
    margin-left: 16px;
    cursor: pointer;
    padding: 2px;
}
.language_selector img.selected{
    width: 40px;
    border: 2px solid #215D89;
    border-radius: 5px;
}