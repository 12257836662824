.result__cards {
    display: flex;
    width: fit-content;
    width: -webkit-fit-content;
    margin: 60px auto;
}

.result__cards .card {
    background: #fff;
    border: 4px solid #ECECEC;
    border-radius: 20px;    
    padding: 32px 48px;
    margin-right: 16px;
    text-align: center;
    min-width: 350px;
}

.result__cards .card.test_pass {
    border: 4px solid #65AA20;
}

.result__cards .card.test_not_pass {
    border: 4px solid #C63838;
}

.result__cards .card:last-child {
    margin-right: 0px;
}

.result_label, .result_label_small {
    display: flex;
    align-items: center;
    justify-content: center;
}

.result_label span {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: rgba(55, 55, 55, 0.75);
    margin-left: 8px;
}

.result_label_without_margin span {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: rgba(55, 55, 55, 0.75);
}

.result_label_small span {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: rgba(55, 55, 55, 0.75);
    margin-left: 8px;
}

.big_result {
    font-weight: 800 !important;
    font-size: 24px !important;
    line-height: 130% !important;
    color: #373737 !important;
}

.bocconi .big_result {
    font-weight: 700 !important;
}

.big_result_text {
    font-weight: 800 !important;
    font-size: 20px !important;
    line-height: 26px !important;
    color: #373737 !important;
}

.bocconi .big_result_text {
    font-weight: 700 !important;
}

.small_result {
    font-weight: 800;
    font-size: 20px;
    line-height: 130%;
    color: #373737;
}

.bocconi .small_result {
    font-weight: 700;
}

.bocconi h1 {
    font-weight: 700;
}

.summary_result {
    margin-top: 16px;
}

.summary_result img, .summary_result span {
    display: block;
    margin-bottom: 8px;
    text-align: center;
}

.summary_result span:first-child {
    font-weight: 600;
font-size: 14px;
line-height: 130%;
color: rgba(55, 55, 55, 0.75);
}

.summary_result span:last-child {
    font-weight: 800;
font-size: 18px;
line-height: 130%;
color: #373737;
}

.modal_anchor {
    font-weight: 800;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    text-decoration-line: underline;
    color: var(--bs-primary);
}

.bocconi .modal_anchor {
    font-weight: 700;
}

.label_small {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: rgba(55, 55, 55, 0.5);   
}

.not_counted_box {
    padding: 12px;
    width: 100%;
    background: #ECECEC;
    border-radius: 16px;
}

.not_counted_box p {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: rgba(55, 55, 55, 0.75);
}

.navigation_buttons button:disabled {
    color: #788E9E;
    cursor: not-allowed;
}

.bocconi .navigation_buttons button:disabled {
    border: none;
}

.discipline_name_text {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: rgba(55, 55, 55, 0.75);
}


/*Result BAR*/
.result_group p{
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #373737;
}
.result_bar{
    min-width: 300px;
    height: 14px;
}

.result_bar .progress-bar{
    background: linear-gradient(270deg, #4F95CC 1.2%, #296D9F 100%);
    border-radius: 16px;
    position: relative;
}
.result_bar .progress-bar::after {
    content: '';
    right: 4px;
    top: 2px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    background: #fff;
}

.bocconi .result_bar .progress-bar {
    background: linear-gradient(270deg, var(--bs-primary) 1.2%, var(--bs-primary) 100%);
}