.reader_page {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: #FFF8E8;
    z-index: 1000;
}

.backButtonReader {
    cursor: pointer;
    vertical-align: middle;
}

.backButtonReader img {
    width: 12px;
    margin-right: 8px;
}

.reader_header {
    margin-top: 40px;
}

.reader_header hr {
    background: #F8EACA;
    border-radius: 7px;
    width: 100%;
    height: 6px;
}

.reader_content {
    overflow-y: scroll;
    max-width: 730px;
    height: 75vh;
    margin: auto;
}

.reader_content.bookmark .paper_bookmark {
    position: relative;
    cursor: pointer;
}
.reader_content.bookmark .paper_bookmark::before {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    background: url(../../assets/icons/remove-bookmark.svg) center;
    background-size: cover;
    left: -15px;
    top: -15px;
}


.tool {
    margin: 0 32px;
    padding: 12px;
    max-width: 64px;
    cursor: pointer;
}

.tool.active {
    background: #296D9F;
    border-radius: 16px;
}

.bocconi .tool.active {
    background: var(--bs-primary);
    border-radius: 16px;
}

.tool img {
    width: 100%;
    vertical-align: middle;
    min-height: 40px;
}

.reader_font_selector {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background:#fff;
    padding: 50px;
}

.slider_container {
    max-width: 230px;
    margin: auto;
    display: flex;
    align-items: center;
}

.paper_bookmark {
    background: url('../../assets/icons/bookmark.svg') center;
    width: 16px;
    vertical-align: middle;
    display: inline-block;
    height: 22px;
    background-repeat: no-repeat;
    margin: 0 4px;
    background-size: contain;
}

.rc-slider-handle {
    background: #296D9F;
    border-color: #296D9F;
}

.rc-slider-dot {
    background: #296D9F;
    border: none;
}

.rc-slider-dot-active {
    background: #296D9F;
    border: none;
}

.bocconi .rc-slider-handle {
    background: var(--bs-primary);
    border-color: var(--bs-primary);
}

.bocconi .rc-slider-dot {
    background: var(--bs-primary);
    border: none;
}

.bocconi .rc-slider-dot-active {
    background: var(--bs-primary);
    border: none;
}

.rc-slider-track {
    display: none;
}

.rc-slider-step {
    height: 4px;
}