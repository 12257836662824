.simulation_screen {
    background: #fff;
}

#time_waiting_animation {
    max-width: 60px;
    display: block;
    margin: auto;
}

.waiting-page {
    margin-top: 8rem;
}

/* MODULES */

.module_waiting_screen {
    position: relative;
    min-height: calc(100vh - 100px);
}

.module_icon {
    display: block;
    max-width: 56px;
    width: 100%;
    margin: auto;
}

.page_bottom {
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
}

.text-timer {
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    color: #373737;
}

.bottom_banner_box {
    width: 100%;
    box-shadow: 0px 0px 8px rgba(123, 123, 123, 0.5);
    background: #fff;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 24px;
}

.icon-close-relative {
    position: absolute;
    right: 0;
    top: 0;
}

.text-bold-h1 {
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #373737;
}