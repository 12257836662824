.exam_question_container {
    background: #F5F5F5;
    padding-top: 35px;
    height: calc(100vh - 160px);
    overflow: hidden scroll;
}

.question-text {
    color: #333333;
    font-weight: 700;
}

.answer {
    padding: 0 0 0 24px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    margin-top: 8px;
    width: 100%;
    cursor: pointer;
    border: 4px solid #fff;
    transition: 0.1s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333333;
    font-weight: 600;
}

.answer_label_number {
    color: #296D9F;
    margin-right: 12px;
}

.bocconi .answer_label_number {
    color: var(--bs-primary);
    margin-right: 12px;
}

.form-check {
    display: flex;
}

.answer label {
    color: #333333;
    font-weight: 500;
    display: flex;
    cursor: pointer;
}

.answer .label_inner {
    width: 90%;
    padding: 20px 20px 20px 0;
}

.answer .uncertain_selector {
    padding: 20px 24px;
}

.block {
    margin-top: 24px;
    width: 100%;
}

.hidden_radio {
    visibility: hidden;
    width: 0;
    float: none !important;
}

.answer.selected {
    border: 4px solid #296D9F;
    transition: 0.1s;
}

.bocconi .answer.selected {
    border: 4px solid var(--bs-primary);
    transition: 0.1s;
}

.answer.selected.is_uncertain {
    border: 4px solid #FCBB0D;;
}

.form-check-input {
    margin-top: .10em;
}

.reset_answer {
    text-decoration-line: underline;
    color: #296D9F;
    cursor: pointer;
    text-align: right;
}

.bocconi .reset_answer {
    text-decoration-line: underline;
    color: var(--bs-primary);
    cursor: pointer;
    text-align: right;
}

.textarea_text_question {
    min-height: 150px;
    width: 100%;
    margin-top: 16px;
    background: #FFFFFF;
    border: 2px solid #296D9F;
    box-sizing: border-box;
    border-radius: 8px;
}

.textarea_text_question:focus, .textarea_text_question:hover {
    border: 2px solid #296D9F;
}

.text_answer_limit{
    display: flex;
    justify-content: flex-end;
}

textarea:focus-visible {
    outline: none !important;
}

.over_limit_text_answer, .over_limit_text_answer:focus, .over_limit_text_answer:hover, .over_limit_text_answer:focus-within, .over_limit_text_answer:focus-visible {
    border: 2px solid #C63838 !important;
    outline: none;
}

.textarea_bottom_label {
    position: absolute;
    left: 0;
    padding: 5px 10px;
    bottom: -28px;
    background: #eee;
    color: #296D9F;
    border-radius: 0 0 20px 20px;
}

.textarea_box {
    position: relative;
    width: 100%;
}

.image_preview {
    width: 100%;
    position: relative;
    max-width: 400px;
}

.image_preview img {
    border: 4px solid #ECECEC;
    border-radius: 16px;  
    max-height: 250px;
    min-height: 200px;
}

.reader_preview {
    background: #FFF8E8;
    border-radius: 14px;
    max-height: 150px;
    overflow: visible;
    border: 4px solid #ECECEC;
    padding: 8px;
    position: relative;
    cursor: pointer;
    font-family: 'Georgia';
}

.reader_preview::after {
    content: '';
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 30px;
    height: 30px;
    background-image: url('../../assets/icons/expand_reader.svg');
    background-size: cover;
}

.bocconi .reader_preview::after {
    background-image: url('../../assets/icons/bocconi/expand_reader.svg');
}

.reader_preview p {
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #373737;
    overflow: hidden;
    max-height: 130px;
    display: block;
}

.lds-ring {
    display: inline-block;
    position: absolute;
    width: 80px;
    height: 80px;
    left:0;
    top:0;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #296D9F;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #296D9F transparent transparent transparent;
}

.bocconi .lds-ring div {
    border: 8px solid var(--bs-primary);
    border-color: var(--bs-primary) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}