.answer__title {
    display: flex;
    align-items: center;
    margin-top: 24px;
}

.answer__title img {
    width: 20px;
}

.answer__title span {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: rgba(55, 55, 55, 0.75);
}

.answer.correct_answer {
    color: #fff;
    background: #65AA20;
    border-color: #65AA20;
}

.answer.wrong_answer {
    color: #fff;
    background: #C63838;
    border-color: #C63838;
}

.answer.missing_answer {
    color: #373737BF;
    background: #F7F7F7;
    border: 4px dashed #CDCDCD;
    box-shadow: none;
}

.answer.correct_answer label, .answer.wrong_answer label {
    color: #fff;
}

.line {
    background: #68BAE8;
    border-radius: 10px;
    width: 100%;
    height: 4px;
    margin: 48px auto 32px;
    border: none;
    opacity: 1;
}

.bocconi .line {
    background: #E0E0E0;
}