/* MAIN STYLE */
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root {
  --bs-primary: #296D9F;
  --bs-warning: #DF580C;
}

.bocconi {
  --bs-primary: #0046AD;
}

.text-warning {
  color: #DF580C !important;
}

.text-tf {
  color: var(--bs-primary);
}

.bocconi .text-tf {
  color: var(--bs-primary);
  font-style: normal;
}

body {
  font-family: 'Nunito' !important;
  font-style: normal;
}

.bocconi {
  font-family: 'Open Sans' !important;
  font-style: normal;
}

.App {
  min-height: 100vh;
  background: url(./assets/backgrounds/bg-general-blue.svg) center no-repeat;
    background-size: cover;
}

.mt-10 {
  margin-top: 5rem;
}

#mainContent {
  background: transparent;
  min-height: calc(100vh);
  padding: 80px 0 0 0;
  position: relative;
}

h1 {
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
}

h2 {
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: rgba(55, 55, 55, 0.5);
}

p {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: rgba(55, 55, 55, 0.75);
}

a {
  text-decoration: none;
  color: var(--bs-secondary);
}

a:hover {
  text-decoration: underline;
  color: var(--bs-secondary);
}

.section-icon{
  width: 56px;
  height: 56px;
  display: block;
}

.inline-icon-small{
  width: 18px;
  vertical-align: middle;
}

.inline-icon-xsmall{
  width: 14px;
  vertical-align: middle;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #272727af;
  z-index: 1000;
}

.loader::before {
  content: '';
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-width: 150px;
  max-height: 150px;
}

/* MAIN CONTENT */

header {
  padding: 16px 16px;
  position: fixed;
  top: 0;
  width: calc(100% - 290px);
  display: flex;
  justify-content: flex-start;
  padding-top: 20px;
  z-index: 100;
  background: #fff;
}


body #mainContent header, body #mainContent footer {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

/* Bottom Modal */

.modal-content {
  padding: 26px;
  max-height: 90vh;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content.bottom_modal {
  opacity: 0;
  transform: translateY(100%);
  transition: all 500ms ease-in-out;
}

.ReactModal__Content--after-open.bottom_modal {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Content--after-close.bottom_modal {
  opacity: 0;
  transform: translateY(100%);
}

/* BUTTONS */

button {
  padding: 12px 24px;
  border-radius: 16px;
  border: none;
  color: #fff;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  background: #296D9F;
  box-shadow: 0px 0px 12px rgba(41, 109, 159, 0.4);
  transition: 0.2s;
  display: block;
  width: fit-content;
}

.bocconi button  {
  padding: 12px 18px;
  border-radius: 16px;
  border: none;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  background: #0046AD;
  box-shadow: none;
  transition: 0.2s;
  display: block;
  width: fit-content;
}

button:hover {
  transform: scale(1.02);
}

button.white {
  border: none;
  background: #fff;
  color: #296D9F;
}

.bocconi button.white {
  border: solid 2.5px #0046AD;
  background: #fff;
  color: #0046AD;
}

button:focus-within, button:focus-visible {
  border: none;
  outline: none;
}

.bocconi button:focus-within, button:focus-visible {
  outline: none !important;
}


/* INPUTS */

.form-check-input:not([role=switch], [type=radio]) {
  width: 20px;
  height: 20px;
  border: 2px solid var(--bs-primary);
  padding: 6px !important;
  background: #fff;
}

.form-check-input:checked[type=checkbox]:not([role=switch]) {
  background: #fff;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGxpbmUgeDE9IjAuNzA3MTA3IiB5MT0iMy42OTI4IiB4Mj0iNC4wMDcxMSIgeTI9IjYuOTkyOCIgc3Ryb2tlPSIjMEIyMjQxIiBzdHJva2Utd2lkdGg9IjIiLz4KPGxpbmUgeDE9IjExLjcwNzEiIHkxPSIwLjcwNzEwNyIgeDI9IjQuMDA3MTEiIHkyPSI4LjQwNzExIiBzdHJva2U9IiMwQjIyNDEiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K");
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
}

.form-check-input:checked {
  border-color: var(--bs-primary) !important;
}

label {
  color: var(--bs-secondary);
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}

input:not([type=radio], [role=switch]), textarea {
  font-size: 18px;
  line-height: 21px;
  padding: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.standardInput {
  border: 0;
  background: #f0f0f0;
  border-radius: 4px;
  outline: none;
}

.pointer {
  cursor: pointer;
}

strong {
  font-weight: 800 !important;
}

.bocconi strong {
  font-weight: 700 !important;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.enableSelect {
  -webkit-touch-callout:default;
  -webkit-user-select:text;
  -moz-user-select:text;
  -ms-user-select:text;
  user-select:text;
}