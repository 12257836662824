#qr-box{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: url('../../assets/backgrounds/home.svg') left bottom no-repeat;
    background-size: 80%;
    display: flex;
    align-items: end;
    justify-content: start;
    padding: 24px;
}
#qr-box.en {
    background: url('../../assets/backgrounds/home-en.svg') left bottom no-repeat;
    background-size: 80%;
}
#qr-box .box {
    max-width: 160px;
}
#qr-box .box p{
    font-size: 14px;
}
#qr-box .box img {
    width:100%
}
#login_screen input {
    max-width: 390px;
    background: #FFFFFF;
    border: 4px solid #4F95CC;
    border-radius: 16px;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    padding: 20px 24px;
    color: #373737;
    margin: 3rem auto 2rem;
    cursor: text;
    z-index: 10;
}
#login_screen input::placeholder {
    font-size: 18px;
    line-height: 130%;
    color: rgba(55, 55, 55, 0.5);
}

.inline-logo {
    max-width: 100px;
}

.footer_info {
    position: absolute;
    bottom: 24px;
    width: 100%;
    z-index: 0;
}

#loginButton:disabled {
    opacity: 0.7;
    filter: saturate(0);
    cursor: not-allowed;
}

.university_carousel{
    max-width: 450px;
    margin: auto;
}
swiper-container {
    align-items: center;
    /* add this will all relevant prefixes */
}