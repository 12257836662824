.zoomable {
    cursor: pointer;
    transition: 0.2s;
}

.zoom-in {
    position: fixed;
    background: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10000;
    cursor: zoom-out;
}

.zoom-in img {
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    width: 80% !important;
    height: auto !important;
    max-height: none !important;
}

.zoom_icon {
    display: block;
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    background: url("../../assets/icons/lens.svg") center no-repeat;
    background-size: cover;
    z-index: 1000;
    cursor: zoom-in;
    transition: 0.2s;
}

.bocconi .zoom_icon {
    background: url("../../assets/icons/bocconi/lens.svg") center no-repeat;
}

.zoom-in .zoom_icon {
    top: 20px;
    right: 20px;
    background: url("../../assets/icons/close.svg") center no-repeat;
    cursor: pointer;
}