.exam_pagination_control {
    width: 100%;
    padding: 18px 18px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    position: fixed;
    left: 0;
    bottom: 0;
    background: #fff;
}

.exam_pagination_control button:disabled {
    border-color: #F0F0F0;
    color: #F0F0F0;
    opacity: 1;
    cursor:not-allowed
}

.calculator_container{
    position: fixed;
    top: 100px;
    left:100px;
    cursor:move
}
button[data-invisible="true"]{
    opacity: 0 !important
}
.tooltip_arrow{
    bottom: 16px !important;
}
.tooltip_arrow::before{
    content: '';
    position:absolute;
    background: #fff;
    bottom: -9px;
    left: 50%;
    width: 18px;
    height: 18px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    transform: translateX(-50%) rotate(-45deg);
    clip-path: polygon(0 1%, 0% 100%, 100% 100%);
}
.tooltip_arrow_top {
    top: 16px !important;
}

.tooltip_arrow_top::before {
    content: '';
    position: absolute;
    background: #fff;
    top: -9px;
    left: 50%;
    width: 18px;
    height: 18px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    transform: translateX(-50%) rotate(135deg);
    clip-path: polygon(0 1%, 0% 100%, 100% 100%);
}
.bubble_left{
    left: -10px !important;
    bottom: 0 !important
}